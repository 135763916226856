import React from 'react';
import { bool, object, string } from 'prop-types';
import { Box, TableCell } from '@material-ui/core';

// Import components
import { ManageButton, BookingStatusActions } from './components';
import { BookingStatusChip } from 'components/elements';

// Import utils
import { useStatusWithActionsTableCell } from './useStateWithActionsTableCell';

// Import styles
import { useStyles } from './StateWithActionsTableCell.styles';

export const StateWithActionsTableCell = ({
	cell,
	isHovered = true,
	minWidth = '270px',
}) => {
	const booking = cell.row.original;

	const { isManageButtonVisible, areActionsVisible } =
		useStatusWithActionsTableCell(booking);

	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			style={{ minWidth }}
			{...cell.getCellProps()}
		>
			<Box className={classes.container}>
				<BookingStatusChip
					booking={booking}
					showRejectTooltip={isManageButtonVisible}
				/>
				{isHovered && (
					<Box className={classes.buttons}>
						{isManageButtonVisible && <ManageButton />}
						{areActionsVisible && <BookingStatusActions />}
					</Box>
				)}
			</Box>
		</TableCell>
	);
};

StateWithActionsTableCell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
	minWidth: string,
};
