import React from 'react';

// Import helpers and utils
import { getStatusType } from './helpers';
import { useProfile } from 'components/utilities';
import { RejectTooltip } from './components';

export const useBookingStatusChip = ({ booking, state, showRejectTooltip }) => {
	const { isAdmin } = useProfile();

	const status = booking?.state || state;

	const statusType = getStatusType(status);

	const getTitle = () => {
		if (isAdmin) {
			if (showRejectTooltip) {
				return <RejectTooltip booking={booking} />;
			} else {
				return booking?.cancel_description || '';
			}
		} else {
			return '';
		}
	};

	const title = getTitle();

	return { status, title, statusType };
};
