import React from 'react';
import clsx from 'clsx';
import { Button, Grid } from '@material-ui/core';

// Import assets
import { CheckmarkIcon } from 'assets/icons';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';

// Import utilities and context
import { useTranslations } from 'components/utilities';
import { useBookingStatusActionsProvider } from 'components/context';

// Import components
import { NotificationAlert, ButtonProgress } from 'components/elements';
import { ExtensionInfoDialog } from 'views/Bookings/BookingDetails/components';
import { CancelAndRejectBookingForm } from '../CancelAndRejectBookingForm';
import { AssignDriverDialog } from '../AssignDriverDialog';

// Import styles
import { useStyles } from './styles';

export const BookingDetailsStatusActionsContent = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		error,
		canCancel,
		canAccept,
		canReject,
		isRejection,
		isCancellation,
		canApproveCancellation,
		clearError,
		handleClose,
		handleCancel,
		handleAccept,
		handleReject,
		handleChange,
		handleApproveCancellation,
		handleAcceptExtension,
		handleRejectExtension,
		isRejectExtensionLoading,
		isAcceptExtensionLoading,
		isExtensionStatusPending,
		isAssignDriverDialogOpen,
		isCorporateCoordinator,
		toggleAssignDriverDialog,
		isButtonDisabled,
		isExtensionDialog,
		uuid,
		booking,
	} = useBookingStatusActionsProvider();

	return (
		<>
			{error && <NotificationAlert open onClose={clearError} message={error} />}
			{isRejection && !isCorporateCoordinator && (
				<CancelAndRejectBookingForm
					booking={booking}
					onChange={handleChange}
					open={isRejection}
					onClose={handleClose}
					dialogTitle="bookings.reject_booking_form.title"
					isRejection
				/>
			)}
			{isCancellation && !isCorporateCoordinator && (
				<CancelAndRejectBookingForm
					booking={booking}
					onChange={handleChange}
					open={isCancellation}
					onClose={handleClose}
					dialogTitle="bookings.cancel_booking_form.title"
				/>
			)}
			{canApproveCancellation && !isCorporateCoordinator && (
				<Grid item>
					<Button
						startIcon={<HighlightOffOutlinedIcon />}
						onClick={handleApproveCancellation}
						className={clsx([classes.button, classes.rejected])}
					>
						{t('bookings.state_actions.confirm_rejection')}
					</Button>
				</Grid>
			)}

			{canCancel && (
				<Grid item>
					<Button
						startIcon={<HighlightOffOutlinedIcon />}
						onClick={handleCancel}
						className={clsx([classes.button, classes.rejected])}
					>
						{t('bookings.state_actions.cancel')}
					</Button>
				</Grid>
			)}

			{canReject && !isCorporateCoordinator && (
				<Grid item>
					<Button
						startIcon={<HighlightOffOutlinedIcon />}
						onClick={handleReject}
						className={clsx([classes.button, classes.rejected])}
					>
						{t('bookings.state_actions.reject')}
					</Button>
				</Grid>
			)}

			{canAccept && !isCorporateCoordinator && (
				<Grid item>
					<Button
						startIcon={<CheckmarkIcon />}
						variant="outlined"
						color="primary"
						onClick={handleAccept}
						className={classes.button}
					>
						{t('bookings.state_actions.accept_booking')}
					</Button>
				</Grid>
			)}

			{isExtensionStatusPending && !isCorporateCoordinator && (
				<>
					<Grid item>
						<ButtonProgress
							variant="contained"
							color="primary"
							className={classes.button}
							isLoading={isAcceptExtensionLoading}
							disabled={isButtonDisabled}
							onClick={handleAcceptExtension}
							circularProgressSize={24}
						>
							{t('bookings.state_actions.accept_extension')}
						</ButtonProgress>
					</Grid>
					<Grid item>
						<ButtonProgress
							startIcon={<CloseIcon />}
							variant="outlined"
							color="inherit"
							className={clsx([classes.button, classes.rejected])}
							isLoading={isRejectExtensionLoading}
							disabled={isButtonDisabled}
							onClick={handleRejectExtension}
							circularProgressSize={24}
						>
							{t('bookings.state_actions.reject_extension')}
						</ButtonProgress>
					</Grid>
				</>
			)}
			{isExtensionDialog && (
				<Grid item>
					<ExtensionInfoDialog />
				</Grid>
			)}
			<AssignDriverDialog
				open={isAssignDriverDialogOpen}
				toggle={toggleAssignDriverDialog}
				bookingUuid={uuid}
			/>
		</>
	);
};
