// Import utils and helpers
import { useTableContextProvider } from 'components/context';
import { BOOKING_STATES } from 'helpers';

export const useStatusWithActionsTableCell = (booking) => {
	const { fetchData } = useTableContextProvider();
	const isManageButtonVisible = booking.state === BOOKING_STATES.REJECTED;
	const areActionsVisible =
		booking.extension_no > 0 &&
		booking.state !== BOOKING_STATES.PAYMENT_PENDING;

	const refreshTable = () => fetchData();

	return {
		refreshTable,
		isManageButtonVisible,
		areActionsVisible,
	};
};
