import { useState } from 'react';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// Import helpers and utils
import { getStatusType } from './helpers';
import { useProfile, useTranslations } from 'components/utilities';
import { BOOKING_STATES, YMD_SLASH_TIME_FORMAT } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useBookingStatusChip = () => {
	const { REJECTED, CANCELED } = BOOKING_STATES;
	const booking = useSelector(previewBookingSelector);
	const { isAdmin } = useProfile();
	const {
		i18n: { language },
	} = useTranslations();
	const [rejectedMenuEl, setRejectedMenuEl] = useState(null);

	const handleCloseRejectedMenu = () => {
		setRejectedMenuEl(null);
	};

	const {
		cancel_description: cancelDescription,
		state: status,
		cancel_reason: cancelReason,
	} = booking || {};

	const {
		name: currentCancelReason,
		old_name: oldCancelReason,
		created_by: createdBy,
		old_created_by: oldCreatedBy,
		created_at: createdAt,
		old_created_at: oldCreatedAt,
	} = cancelReason || {};

	const isRejectedOrCanceled = status === REJECTED || status === CANCELED;

	const statusDescription = isAdmin ? cancelDescription || '' : '';

	const statusType = getStatusType(status);

	const rejectionReasons = [
		{
			title: 'bookings.preview_booking.reject_reasons.old_reason',
			createdAt: oldCreatedAt
				? format(new Date(oldCreatedAt), YMD_SLASH_TIME_FORMAT)
				: '',
			reason: oldCancelReason?.[language] || '',
			by: oldCreatedBy,
		},
		{
			title: 'bookings.preview_booking.reject_reasons.new_reason',
			createdAt: createdAt
				? format(new Date(createdAt), YMD_SLASH_TIME_FORMAT)
				: '',
			reason: currentCancelReason?.[language] || '',
			by: createdBy,
		},
	];

	return {
		status,
		statusDescription,
		statusType,
		isRejectedOrCanceled,
		rejectedMenuEl,
		handleCloseRejectedMenu,
		rejectionReasons,
		setRejectedMenuEl,
	};
};
